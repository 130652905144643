#cookie-bar {background:#262626; height:auto; line-height:24px; color:#fff; text-align:center; padding:10px 0;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {font-size:12px; color: #fff;margin:0; padding:0;}
#cookie-bar a {font-size:16px;color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding: 3px 15px; margin-left:8px;}
#cookie-bar .cookiesurl {font-size:12px !important; color:#7cc8ff !important; text-decoration:none !important; padding: 0 !important; margin-left:0px !important;}
#cookie-bar .cookiesurl:hover {color:#7cc8ff !important; text-decoration:underline !important; font-size: 13px !important;}
#cookie-bar .cb-enable {background:#9c9c9c;}
#cookie-bar .cb-enable:hover {background: #4C5B5C;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#9c9c9c;}
#cookie-bar .cb-policy:hover {background:#0f4261;}