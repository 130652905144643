@import "bootstrap";
@import "font-awesome";
@import "font-awesome/solid";
@import "font-awesome/brands";
@import "font-awesome/regular";
@import "font-awesome/v4-shims";
@import "magnific-popup";
@import "revoluation-slider";

@import "owl/owl.carousel";
@import "owl/owl.theme";
@import "owl/owl.transitions";
@import "jquery.cookiebar";
@import "remodal";
@import "remodal-default-theme";


/*# sourceMappingURL=vendor.css.map */
/*** Background ajax loader ***/
.overlap-ajax {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.70);
  z-index: 1500;
  display: none;
  text-align: center;
}

#Layer_2 {
  width: 200px;
  height: 200px;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  position: absolute;
}

.dnone {display: none}

.dflex {display: flex !important; flex-wrap: wrap;}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.calendar-title > a.right > i {
  margin-right: 10px;
  transition: all 0.3s;
}

.calendar-title > a.left > i {
  margin-left: 10px;
  transition: all 0.3s;
}

/* Center image in service box */

.vertical-image-box{
  align-items: center;
  display: inline-flex;
  min-height: 250px;
}

/* Center image in pack box */

.img-layette {
  height: 350px;
  @media (max-width: 450px) {
    height: auto;
  }
  margin: 0px 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

/* Pack Layette Small version */
@media (min-width: 500px) and (max-width: 767px) {
  .layette {
    margin-left: 25px;
    margin-right: 25px;
  }

  .layette_quantity_input {
    width: 90% !important;
  }

  .btn-layette {
    width: 90% !important;
  }
}

/* img-responsive style in contents */

.contents-container img {
  display: block;
  max-width: 100%;
  height: auto!important;
}

/* DRAG AND DROP */

div#draganddrop {
  border: 2px dashed #b4b4b4;
  height: 150px;
  text-align: center;
  line-height: 60px;
  color: #b4b4b4;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
  margin: 0;
}

button#dragndrop {
  border: 0;
  color: #fff;
  margin-top:5px;
  float:left;
}

i.fa.fa-upload.custom {
  font-size: 50px;
}

input[type=file]#fileupload {
  width: 100%;
}

#draganddrop p.file-attached {
  margin: 10px;
  line-height: 20px;
  text-align: left;
  font-size: 0.8em;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

#upload-progress-bar {
  background-color: #abb633;
  height: 10px;
  width: 0%;
  margin-top: 5px;
}

/*ESTIL PER LABEL USADA EN CHECKBOX INTERESOS*/
.no-bold{
  font-weight: normal;
}

/* RECAPTCHA V3 STYLES */

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-policies {
  font-size: 10px;
  color: #ddd;
  a {
    color: #ddd;
  }
}

/* PROMO PRICE */

.block-promos {
  .panel-body-1 {
    position: relative;
    .promo-price {
      top: -15px;
    }
  }
}

/* FLEX CONTAINER */
#subslider {
    @media (min-width: 992px) {
      .flex-container, .flex-item {
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
      }
    }

    .flex-item {
      width: 100%;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;
    }

    div.flex-item > a {
      text-decoration: none;
      width: 100%;
    }

    .single-call-to-action {
      min-height: 130px;
      padding-top: 20px;
    }
}

button.btn.dropdown-toggle.text-uppercase {
  text-transform: uppercase;
}

/* PADDINGS */

  .pb-10 {
    padding-bottom: 10px !important;
  }
